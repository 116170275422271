import { ETextStyleVariant, GeneralHeartUnfilledIcon, Link, Text } from '@outdoorsyco/bonfire';
import cs from 'classnames';
import Image from 'next/image';
import { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Loading from '@/components/switchback/Loading/Loading';
import { IFavorite } from '@/redux/modules/favorites';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { trackWishlistSelectedEvent } from '@/services/analytics/wishlists';
import { EWishlistSelectedSource } from '@/services/analytics/wishlists/types';

interface WishlistImageProps {
  coverImageUrl: string;
}

const WishlistImage = memo(({ coverImageUrl }: WishlistImageProps) => {
  const intl = useIntl();
  const [imageError, setImageError] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  if (!coverImageUrl || imageError) {
    return (
      <div className="flex items-center justify-center w-full h-full bg-gray-100">
        <GeneralHeartUnfilledIcon fontSize={24} className="text-gray-310" />
      </div>
    );
  }

  return (
    <>
      {!isImageLoaded && (
        <div className="flex items-center justify-center w-full h-full bg-gray-100">
          <Loading />
        </div>
      )}
      <Image
        src={coverImageUrl}
        alt={intl.formatMessage({ id: 'mDwJhG', defaultMessage: 'Wishlist cover image' })}
        fill
        className="object-cover"
        onError={() => setImageError(true)}
        onLoadingComplete={() => setIsImageLoaded(true)}
      />
    </>
  );
});

WishlistImage.displayName = 'WishlistImage';

interface WishlistToastProps {
  selectedWishlistId: number;
  coverImageUrl: string;
  wishlistTitle: string;
  rentalId: number;
  wishlistItemCount: number;
  creatorId?: number;
  onChangeWishlist: (userFavorites: IFavorite[]) => void;
}

export const WishlistToast = ({
  selectedWishlistId,
  coverImageUrl,
  wishlistTitle,
  rentalId,
  wishlistItemCount,
  creatorId,
  onChangeWishlist,
}: WishlistToastProps) => {
  const intl = useIntl();
  const userFavorites = useSelector(getUserFavorites);

  const handleClick = () => {
    onChangeWishlist(userFavorites.data);
  };

  const handleWishlistLinkClick = () => {
    trackWishlistSelectedEvent({
      wishlistID: selectedWishlistId,
      wishlistName: wishlistTitle,
      coverPhotoRentalID: rentalId,
      wishlistItemCount,
      wishlistCreatorID: creatorId || null,
      source: EWishlistSelectedSource.WISHLIST_TOAST,
    });
  };

  return (
    <div className={cs('p-2 pr-4 text-white bg-gray-900 rounded-xl')}>
      <div className="flex items-center gap-2">
        <div className="relative flex-shrink-0 w-12 h-12 overflow-hidden rounded-lg">
          <WishlistImage coverImageUrl={coverImageUrl} />
        </div>
        <div className="items-center w-full grid grid-cols-[minmax(0,1fr)_auto] gap-x-2">
          <div className="min-w-0 overflow-hidden text-ellipsis whitespace-nowrap">
            <Text className="inline pr-1" variant={ETextStyleVariant.LegalRegular}>
              <FormattedMessage id="lgX/sS" defaultMessage="Saved to" />
            </Text>
            <Link
              className="inline"
              label={wishlistTitle}
              href={`/wishlists/${selectedWishlistId}`}
              textVariant={ETextStyleVariant.LegalBold}
              onDark
              onClick={handleWishlistLinkClick}
            />
          </div>
          <div>
            <Link
              label={intl.formatMessage({ defaultMessage: 'Change', id: 'BY343C' })}
              textVariant={ETextStyleVariant.LegalLink}
              onDark
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
